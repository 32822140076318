@import "../../styles/variables";

.layout {
    position: relative;

    [data-reach-skip-link]:focus {
        color: $black;
        background-color: $white;
        left: auto;
        top: 0;
        width: 250px;
        height: auto;
        overflow: auto;
        padding: 15px;
        margin: 0;
        border-radius: 15px;
        border: 4px solid $pewter3;
        text-align: center;
        font-size: 20px;
        z-index: 999;
    }

    &Fixed {
        @media ($below-mobile) {
            position: fixed;
        }
    }
}
