@import "../../styles/variables";

$utility-bar-height: 30px;

:export {
    utilityBarHeight: $utility-bar-height;
}

.utilityBar {
    composes: utilityBar from "./utility-bar.module.scss";
}

.content {
    composes: content from "./utility-bar.module.scss";
}

.promo {
    composes: promot from "./utility-bar.module.scss";
}

.menu {
    composes: menu from "./utility-bar.module.scss";
}

.menuLink {
    composes: menuLink from "./utility-bar.module.scss";
    font-family: $secondary-font-family;
}
