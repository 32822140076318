@import "../../styles/variables";

.textlikeInput {
    @include textinput;

    &[type="search"] {
        padding-right: 50px;
        appearance: none;

        &::-webkit-search-cancel-button {
            appearance: none;
        }
    }
}

.searchCollapsed {
    display: inline-block;

    .textlikeInput {
        opacity: 0;
        width: 3em;
        padding-right: 0;
    }

    .searchIcon {
        padding: 0 0.75em;
    }
}

.inputWrapper {
    position: relative;
    font-size: 16px;
}

.searchIcon {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 0.9375em;
    color: $pewter3;

    svg {
        width: 1.25em;
        height: 1.25em;
    }
}

.toggleIcon {
    svg {
        width: 1.5em;
        height: 1.5em;
    }
}

.textarea {
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid $pewter2;
    resize: vertical;
}

.inputError {
    border-color: $red;
}

.required {
    color: $red;
}

.passwordInput {
    display: flex;
    align-items: center;
}

.passwordShowHide {
    display: inline-block;
    background-color: white;
    font-size: 14px;
    font-weight: 300;
    padding: 10px;
    position: absolute;
    right: 1px;
    text-decoration: underline;
    text-align: right;
    width: auto;
}
