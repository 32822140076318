@import "../../styles/variables";

.nav {
    @media ($above-mobile) {
        position: sticky;
        top: 150px;
        margin-bottom: 80px;
    }

    :global dd {
        &.active {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 4px;
                height: 20px;
                background-color: $satin-brass;
            }

            a {
                font-weight: 400;
            }
        }
    }

    [data-reach-menu-popover] {
        width: calc(100% - calc(var(--container-min-margin) * 2));
        z-index: 1;
    }
}

.desktopList {
    dt + div {
        @media ($below-mobile) {
            display: none;
        }
    }
}

.title {
    composes: primaryAllCaps from "../../styles/typography.module.scss";
    margin-bottom: 10px;

    > a {
        color: inherit;
        text-decoration: none;
    }

    @media ($above-mobile) {
        padding-left: 15px;
    }
}

.link {
    composes: primaryLight from "../../styles/typography.module.scss";
    composes: linkReset from "../Clickable/button.module.scss";
    display: inline-block;
    padding: 7px 15px;
}

.mobileMenu {
    @media ($above-mobile) {
        display: none;
    }
}

.menuListActive {
    font-weight: 700;
}
