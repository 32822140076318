@import "../../styles/variables";

$desktop-nav-height: 110px;
$condensed-nav-height: 56px;

$nav-icon-padding: 0.75em;
$icon-group-tablet-position-adjustment: calc(
    #{$container-margin-tablet} - #{$nav-icon-padding}
);
$icon-group-mobile-position-adjustment: calc(
    #{$container-margin-mobile} - #{$nav-icon-padding}
);

:export {
    desktopNavHeight: $desktop-nav-height;
}

// Main nav is bundled with skip link inside a header tag, putting it in there
// broke sticky. `display: contents` is an amazing solve, but apparently some
// screen readers don't work well with it. So the sticky styles were pulled out
.headerWrapper {
    position: sticky;
    top: 0;
    z-index: 110;
}

.mainNav {
    position: relative;
    background-color: $white;
    height: $desktop-nav-height;

    .logoBlock {
        color: $black;
    }

    @media ($above-tablet) {
        display: flex;
        align-items: flex-start;
        box-shadow: 0 1px 5px rgba($pewter2, 0.5);

        .content {
            width: 100%;
        }

        .logoBlock {
            height: 36px;
            margin-bottom: 20px;
        }

        .burgerClickable {
            display: none;
        }

        &:not(.condensed) {
            .content {
                padding: 20px 0 15px;
            }

            .accountNav {
                top: 0.75em;
                transform: none;
            }
        }

        .burgerSearch {
            top: 1.25em;
            left: 0;
            transform: none;
        }

        .accountNav .search {
            display: none;
        }
    }

    @media ($below-tablet) {
        .content {
            padding: 20px 0;
        }

        .logoBlock {
            height: 20px;
        }

        .logo {
            width: 99px;
            height: 20px;
        }

        .burgerSearchOpen {
            z-index: 3;

            @media ($below-tablet) {
                width: calc(
                    100% - #{$icon-group-tablet-position-adjustment} * 2
                );
            }

            @media ($below-mobile) {
                width: calc(
                    100% - #{$icon-group-mobile-position-adjustment} * 2
                );
            }

            .searchWrapper {
                width: 100%;

                > div {
                    flex: 1;
                }
            }
        }

        .accountNav .search {
            display: none;
        }

        .accountLinkText {
            display: none;
        }

        .closeIcon {
            display: flex;
        }
    }

    @media ($above-mobile) and ($below-tablet) {
        height: 70px;

        .logoBlock {
            height: 30px;
        }

        .logo {
            width: 149px;
            height: 30px;
        }
    }

    @media ($below-mobile) {
        height: $mobile-nav-height;

        .content {
            padding: 15px 0;
        }
    }

    @media ($above-tablet) {
        &.condensed {
            height: $condensed-nav-height;
            box-shadow: 0 1px 5px rgba($pewter3, 0.5);
            align-items: center;
            margin-bottom: $desktop-nav-height - $condensed-nav-height;

            .logoBlock {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 24px;
            }

            .logo {
                width: 118px;
                height: 24px;
            }

            .burgerClickable {
                display: none;
            }

            .burgerSearch .search {
                display: none;
            }

            .accountLinkText {
                display: none;
            }

            .condensedSearchWrapper {
                display: flex;
            }

            .accountNavOpen {
                width: calc(100% - 130px);

                ul {
                    width: 100%;

                    li:first-child {
                        flex: 1;

                        div,
                        input {
                            width: 100%;
                        }
                    }
                }
            }

            .searchWrapper {
                display: none;
            }

            .closeIcon {
                display: flex;
            }
        }
    }
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.content {
    composes: container from "../../styles/layout.module.scss";
    position: relative;

    @media ($below-tablet) {
        // Needs to be full width, so we're doing padding instead of margin here
        margin: 0;
        padding: 0 $container-margin-tablet;
        background-color: $white;
        box-shadow: 0 1px 3px rgba($pewter3, 0.5);
        z-index: 2;
    }

    @media ($below-mobile) {
        padding: 0 $container-margin-mobile;
    }

    @media print {
        box-shadow: 0 0 0 transparent;
    }
}

.logoAndMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;
}

.logo {
    width: 177px;
    height: 36px;
}

.accountNav {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -$nav-icon-padding;
    color: $pewter3;
    z-index: 2;

    @media ($below-tablet) {
        right: $icon-group-tablet-position-adjustment;
    }

    @media ($below-mobile) {
        right: $icon-group-mobile-position-adjustment;
    }

    @media print {
        display: none;
    }

    ul {
        display: flex;
    }
}

.accountNavList {
    composes: listReset from "../../styles/utility.module.scss";
}

.navIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    text-decoration: none;
    color: inherit;
    padding: $nav-icon-padding;
    transition: color 0.2s;

    &:hover {
        color: $black;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
    }
}

.accountLinkText {
    font-family: var(--main-nav--account-link-text-font, #{$base-font-family});
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
}

.burgerSearch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    display: flex;
    color: $pewter3;
    z-index: 2;

    @media ($below-tablet) {
        left: $icon-group-tablet-position-adjustment;
    }

    @media ($below-mobile) {
        left: $icon-group-mobile-position-adjustment;
    }

    @media print {
        display: none;
    }
}

.searchInput {
    font-size: 14px;
}

.condensedSearchWrapper {
    display: none;
    align-items: center;
    padding: 0;
    height: 3em;
}

.searchWrapper {
    display: flex;
    align-items: center;
}

.closeIcon {
    display: none;
}
