.overflowHidden {
    overflow: hidden;
}

// Should work with ul, ol, and dl, including situations where dls are wrapped
// in a div.
:where(.listReset) {
    margin: 0;
    padding: 0;
    list-style: none;

    > :where(li, dt, dd),
    > :where(div) dd {
        margin: 0;
        padding: 0;
    }
}

:where(.fieldsetReset) {
    margin: 0;
    padding: 0;
    border: 0;

    > :where(legend) {
        float: left;
        max-width: 100%;
        color: inherit;
        white-space: normal;

        + * {
            clear: both;
        }
    }
}
