@import "../../styles/variables";

.errorMessage {
    color: $red;
    font-size: 12px;
    line-height: 1.333em;
    margin: 10px 0 0;
}

.errorList {
    margin: 10px 0 0;
    padding-left: 1em;
}
