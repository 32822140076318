@import "../../styles/variables";

.loadingDots {
    --dot-width: 0.5em;
    --start-color: #{$black};
    --end-color: rgba(#{$black}, 0.2);
    --animation-length: 1.5s;

    position: relative;
    width: var(--dot-width);
    height: var(--dot-width);
    border-radius: calc(var(--dot-width) * 0.5);
    background-color: var(--start-color);
    animation: dotFlashing var(--animation-length) infinite linear;
    animation-delay: calc(var(--animation-length) * 0.33);

    &::before,
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: var(--dot-width);
        height: var(--dot-width);
        border-radius: calc(var(--dot-width) * 0.5);
        background-color: var(--start-color);
    }

    &::before {
        left: calc(var(--dot-width) * -1.5);
        animation: dotFlashing var(--animation-length) infinite ease-in;
        animation-delay: 0s;
    }

    &::after {
        left: calc(var(--dot-width) * 1.5);
        animation: dotFlashing var(--animation-length) infinite ease-out;
        animation-delay: calc(var(--animation-length) * 0.67);
    }
}

@keyframes dotFlashing {
    0% {
        background-color: var(--start-color);
    }
    50% {
        background-color: var(--end-color);
    }
    100% {
        background-color: var(--start-color);
    }
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba($white, 0.6);

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}
