@import "../../styles/variables";
@import "../../styles/typography-variables";

:where(.buttonReset) {
    @include button-reset;
}

:where(.linkReset) {
    color: inherit;
    text-decoration: none;
}

.link {
    font-size: inherit;
    font-weight: 300;
    line-height: 1.5em;
    text-decoration: underline;

    &:disabled,
    &.disabled {
        cursor: default;
        pointer-events: none;
        color: $pewter3;
    }

    &:focus-visible,
    &:active {
        outline: 1px solid $pewter3;
    }

    &:visited {
        color: inherit;
    }
}

.button {
    @include button;
}

.buttonPrimary {
    @include button-primary;
}

.buttonSecondary {
    @include button-secondary;
}

.buttonText {
    @include button-text;
}

div.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
