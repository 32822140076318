@import "../../styles/variables";
@import "../../styles/typography-variables";

.richText {
    > *:first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    hr {
        background-color: $pewter2;
        border: 0;
        height: 1px;
        margin: 1em 0;
    }

    :global {
        .eyebrow {
            @include secondary-all-caps;
        }
    }

    a {
        color: inherit;
        font-weight: 300;
    }

    p {
        font-family: var(--rich-text--font-family, #{$base-font-family});
    }

    ol,
    ul {
        padding-left: 1.5em;

        li {
            margin: 0 0 1rem 0;
            padding-left: 0.3em;

            @media ($below-mobile) {
                padding-left: 0;
            }

            &::marker {
                color: $satin-brass;
                font-weight: 700;
            }
        }
    }

    ul {
        li {
            list-style: square;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
    }

    :global {
        .button {
            @include button-primary;
        }

        .button--secondary {
            @include button-secondary;
        }

        .button--tertiary {
            @include button-text;
            padding-top: 0;
            padding-bottom: 0;
        }

        // When we have button groups, we want some spacing
        // But we can't just do adjancent selectors or :only-of-type because then it targets
        // inline links in paragraphs too.
        // :has is better because it lets us use margin-rights, which look better when the
        // buttons break on mobile
        p {
            .button:has(+ a),
            .button--secondary:has(+ a),
            .button--tertiary:has(+ a) {
                margin-right: 1em;
            }
        }
        // TODO remove this once Firefox is on board
        @supports not selector(:has(a, b)) {
            p {
                .button + a,
                .button--secondary + a,
                .button--tertiary + a {
                    margin-left: 1em;
                }
            }
        }

        .button,
        .button--secondary {
            margin-top: 5px;
        }

        .richtext-image {
            display: block;
            max-width: 100%;
            height: auto;
            margin-bottom: 0.5em;

            &.full-width {
                width: 100%;
            }

            &.right {
                margin-left: auto;
            }
        }
    }

    small {
        color: $pewter3;
    }
}

// Aligned Rich Text
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.withBackground {
    composes: maxSiteWidth from "../../styles/layout.module.scss";
    padding: 3.125rem 0 2.75rem 0;

    & + & {
        padding-top: 0;
    }

    @media ($below-mobile) {
        padding: 1.875rem 0 1.5rem 0;
    }
}

// for use when Aligned Rich Text is its own Block, and isn't used as a Sub-block
.container {
    composes: gridContainer from "../../styles/layout.module.scss";
}

// Rich Text Columns
.richTextColumnsContainer {
    composes: gridContainer from "../../styles/layout.module.scss";
    --grid-gap: 40px;

    @media ($below-mobile) {
        --grid-gap: 20px;
    }
}

.full {
    composes: fullWidthContainer from "../../styles/layout.module.scss";

    .columns {
        & > div {
            @media ($above-tablet) {
                width: 33%;
            }
        }
    }
}

.padded {
    composes: paddedContainer from "../../styles/layout.module.scss";
}

.columns {
    display: flex;
    gap: 15px;
    justify-content: center;
    padding: 1.5rem 0 0.5rem;

    @media ($below-mobile) {
        & {
            flex-direction: column;
            gap: 10px;
            padding: 1rem 0 0 0;
        }
    }

    & > div {
        width: 42%;

        @media ($above-mobile) and ($below-tablet) {
            & {
                width: 50%;
            }
        }

        @media ($below-mobile) {
            & {
                width: 100%;
            }
        }
    }
}
