@import "../../styles/variables";

.mainMenu {
    composes: listReset from "../../styles/utility.module.scss";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;

    &.hasUtilityMenu > li:last-child a {
        padding-bottom: 30px;
    }

    &.condensedLayout {
        align-items: center;
        padding-bottom: 0;
    }

    @media ($above-tablet) {
        opacity: 1 !important;
        transform: translateY(0) !important;
        pointer-events: auto !important;
    }

    @media ($below-tablet) {
        position: fixed;
        background-color: $white;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        top: 70px;
        padding-top: 5px;
        padding-bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        z-index: 1;

        > li {
            width: 100%;
        }
    }

    @media ($below-mobile) {
        top: 50px;
    }
}

@mixin show-dropdown {
    @media ($above-tablet) {
        > button {
            position: relative;

            $extra-hitbox-width: 15px;

            &:before {
                content: "";
                width: calc(100% + #{$extra-hitbox-width * 2});
                height: 20px;
                background: $white;
                position: absolute;
                bottom: 0;
                right: -$extra-hitbox-width;
                z-index: 0;
            }

            span {
                position: relative;
                z-index: 1;
            }
        }
        > div {
            display: block;
        }
    }
}

.mainListItem {
    &:hover,
    &:focus-within {
        @include show-dropdown;
    }

    &:hover,
    &:focus-within,
    &.pathActive {
        @media ($above-tablet) {
            .clickable span {
                box-shadow: inset 0 -3px $satin-brass;
            }
        }
    }

    @media ($below-tablet) {
        &.active {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $white;
            z-index: 3;

            button {
                padding-top: 20px;
                justify-content: center;
            }

            svg {
                position: absolute;
                left: 30px;
                transform: rotate(180deg);
            }
        }
    }

    @media ($below-mobile) {
        &.active {
            svg {
                left: 15px;
            }
        }
    }

    + .utilityListItem {
        margin-top: 15px;

        > a {
            padding-top: 30px;
        }
    }
}

.clickable {
    font-family: var(--main-menu--clickable-font, #{$secondary-font-family});
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 15px;

    svg {
        display: none;
    }

    @media ($above-tablet) {
        span {
            display: inline-block;
            padding: 15px 0;
            box-shadow: inset 0 0 $satin-brass;
            transition: box-shadow 0.2s;

            .condensedLayout & {
                padding: 18px 0;
            }
        }
    }

    @media ($below-tablet) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 30px;
        font-size: 16px;
        line-height: 22px;
        text-align: left;

        svg {
            display: inline-block;
            width: 16px;
            height: 16px;
        }
    }

    @media ($below-mobile) {
        padding: 15px;
    }
}

.utilityClickable {
    background-color: $pewter1;
    color: inherit;
    font-family: $base-font-family;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 15px;

    @media ($above-tablet) {
        display: none;
    }

    @media ($above-mobile) {
        padding-left: 30px;
        padding-right: 30px;
    }
}
