@import "../../styles/variables";

.label {
    display: block;
    margin-bottom: 5px;
    color: var(--form-label--font-color, $pewter3);
    font-family: var(--form-label--font-family, inherit);
    font-size: 11px;
    line-height: 1.25em;
}

.bigLabel {
    composes: primaryAllCaps from "../../styles/typography.module.scss";
    padding-bottom: 10px;
}
