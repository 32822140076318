@import "../../styles/variables";

$utility-bar-height: 30px;

:export {
    utilityBarHeight: $utility-bar-height;
}

.utilityBar {
    position: relative;
    height: $utility-bar-height;
    background-color: $black;
    color: $white;
    font-size: 12px;
    line-height: 30px;
    z-index: 1;

    @media ($below-tablet) {
        display: none;
    }

    a {
        color: $white;
    }
}

.content {
    composes: container from "../../styles/layout.module.scss";
    display: flex;
    justify-content: space-between;

    &:not(.hasPromo) {
        justify-content: flex-end;
    }
}

.promo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.menu {
    composes: listReset from "../../styles/utility.module.scss";
    display: flex;
    flex-shrink: 0;
}

.menuLink {
    composes: buttonReset from "../Clickable/button.module.scss";
    margin-left: 20px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    transition: box-shadow 0.2s;
    box-shadow: inset 0 0 white;

    &:hover,
    &.active {
        box-shadow: inset 0 -2px white;
    }
}
