@import "../../styles/variables";

.splitLayout {
    margin-top: 80px;

    @media ($below-mobile) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.hideMarginTop {
    margin-top: 0;

    @media ($below-mobile) {
        margin-top: 0;
    }
}

.useGridContainer {
    composes: gridContainer from "../../styles/layout.module.scss";
}

.splitLayoutNav {
    grid-column: 1 / span 3;

    @media ($below-mobile) {
        grid-column: span 4;
        margin-bottom: 40px;
    }
}

.splitLayoutBody {
    grid-column: 4 / span 9;

    @media ($below-mobile) {
        grid-column: span 4;
    }
}
