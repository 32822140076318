@import "./variables";
@import "./typography-variables";

:export {
    headingLetterSpacing: $heading-letter-spacing;
    h2Size: $h2-size;
}

.secondaryAllCaps {
    @include secondary-all-caps;
}

.primaryAllCaps {
    @include primary-all-caps-bold;
}

.primaryLight {
    font-family: $base-font-family;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
}
