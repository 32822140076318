@import "../../styles/variables";

.hoverZoom {
    overflow: hidden;

    &:hover > * {
        transform: scale(1.02);
    }

    > * {
        width: 100%;
        transform: scale(1);
        transition: transform 0.2s;
    }
}

.hoverBorderShadow {
    border: 1px solid transparent;
    box-shadow: 0 0 0 rgba($pewter2, 0.5);
    transition:
        border-color 0.2s,
        box-shadow 0.2s;

    &:hover {
        border: 1px solid $satin-brass;
        box-shadow: 0 1px 5px rgba($pewter2, 0.5);
    }
}
