@import "../../styles/variables";

.subMenu {
    // WARNING! If you ever change submenu to activate by some method other than
    // display: none/block, read the PrimaryNavCategory notes and make sure
    // you're not breaking aria functionality.
    display: none;
    background-color: $white;
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid $pewter1;

    // This exists for Storybook only
    &.alwaysVisible {
        display: block !important;
        top: 0 !important;
    }

    @media ($above-tablet) {
        top: 100%;
        box-shadow: 0 3px 5px -3px rgba($pewter2, 0.5);
    }

    @media ($below-tablet) {
        display: block;
        width: 100%;
        top: 0;
        height: 0;
        overflow: hidden;
        transform: translateX(100%);
        transition: transform 0.3s;

        &.mobileActive {
            $main-menu-clickable-height: 57px;
            top: 100%;
            height: calc(
                100vh - #{$mobile-nav-height} - #{$main-menu-clickable-height}
            );
            transform: translateX(0);
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
    }
}

.contentWrapper {
    composes: gridContainer from "../../styles/layout.module.scss";
    margin-top: 30px;
    margin-bottom: 30px;

    @media ($below-tablet) {
        margin-top: 15px;
    }
}

.mainContent {
    grid-column: 1 / span 9;

    @media ($below-tablet) {
        grid-column: 1 / span 4;
    }
}

.divider {
    @media ($above-tablet) {
        width: 100%;
        height: 100%;
        grid-column: 10 / span 1;
        border-right: 1px solid $pewter1;
    }

    @media ($below-tablet) {
        grid-column: 1 / span 12;
        border-top: 1px solid $pewter1;
        margin-top: 15px;
        padding-top: 10px;
    }

    @media ($below-mobile) {
        grid-column: 1 / span 4;
    }
}

.subMenuList {
    composes: listReset from "../../styles/utility.module.scss";
    display: flex;
    justify-content: center;

    @media ($above-tablet) {
        gap: 20px;
    }

    @media ($below-tablet) {
        flex-direction: column;
    }
}

.productList {
    composes: subMenuList;
    flex-wrap: wrap;
}

.contentList {
    composes: subMenuList;
    > li {
        @media ($above-tablet) {
            width: 33%;
        }
    }
}

.navLinksPanel {
    grid-column: 11 / span 2;
    font-size: 14px;
    line-height: 1.4em;

    @media ($below-tablet) {
        grid-column: 1 / span 4;
    }

    dt {
        margin-bottom: 8px;
        font-weight: 700;
        letter-spacing: 0.07em;
        text-transform: uppercase;
    }

    a {
        display: inline-block;
        padding: 8px 0;
        color: inherit;
        font-weight: 300;
        text-decoration: none;

        @media ($below-tablet) {
            padding: 12px 0;
        }
    }
}

.navFeature a,
.navProduct a,
.ctaWrapper a {
    color: inherit;
    font-size: 14px;
    line-height: 1.4em;
}

.navProduct a,
.navLinksPanel a {
    &:hover {
        text-decoration: underline;
    }
}

.navFeature {
    div {
        margin-bottom: 0.8em;

        @media ($below-tablet) {
            display: none;
        }
    }

    a {
        display: inline-block;
        text-decoration: none;

        @media ($below-tablet) {
            padding: 12px 0;
            font-family: $base-font-family;
            letter-spacing: 0;
            text-transform: none;
        }
    }
}

.navProduct {
    display: flex;

    @media ($above-tablet) {
        justify-content: center;
        width: calc(20% - 16px);
        text-align: center;
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 10px;
        font-family: $base-font-family;
        letter-spacing: 0;
        text-decoration: none;
        text-transform: none;

        @media ($below-tablet) {
            padding: 12px 0;
        }
    }

    img {
        width: 110px;
        height: 110px;
        margin-bottom: 1em;

        @media ($below-tablet) {
            display: none;
        }
    }
}

.ctaWrapper {
    margin-top: 12px;

    @media ($above-tablet) {
        margin-top: 30px;
        text-align: center;
    }
}

.cta {
    font-family: var(--submenu--cta-font, #{$secondary-font-family});
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
    text-underline-offset: var(--button-text--underline-offset, auto);
}

.navFeature {
    composes: secondaryAllCaps from "../../styles/typography.module.scss";
}

.overlay {
    $desktop-nav-height: 110px;
    background-color: rgba($black, 0.3);
    position: fixed;
    top: $desktop-nav-height;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$desktop-nav-height});
    pointer-events: none;
    z-index: -1;

    @media ($below-tablet) {
        display: none;
    }
}
