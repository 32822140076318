@use "sass:math";
@import "../../styles/variables";

$grid-gap: 20px;

.emtekStyle {
    background-color: $navy;
}

.schaubStyle {
    background-color: $black;
}

.footerNav {
    color: $white;
    padding: 40px 0 30px;

    dt,
    .featuredLink {
        margin-bottom: 4px;
        font-weight: 700;
        letter-spacing: 0.07em;
        line-height: 1.33em;
        text-transform: uppercase;
    }

    a,
    dt span {
        display: inline-block;
        padding: 8px 0;
        color: inherit;
        line-height: 1.33em;
        text-decoration: none;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.subscribe {
    composes: container from "../../styles/layout.module.scss";
    display: flex;
    gap: $grid-gap;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $pewter2;

    @media ($above-tablet) {
        padding-left: calc(#{percentage(math.div(1, 12))} + #{$grid-gap});
        padding-right: calc(#{percentage(math.div(1, 12))} + #{$grid-gap});
    }

    @media ($above-mobile) {
        > * {
            width: 50%;
        }
    }

    @media ($below-mobile) {
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    p {
        margin: 0;
    }

    form {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        @media ($below-mobile) {
            flex-direction: column;
            align-items: center;
        }

        // wrapper around textinput
        > div {
            width: 100%;

            @media ($above-mobile) {
                flex: 1;
            }

            // error message
            p {
                margin-top: 7px;
                color: $white;

                @media ($above-mobile) {
                    position: absolute;
                }
            }
        }

        input {
            border: 0;
            border-bottom: 2px solid $satin-brass;
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            color: $white;

            &:focus-within {
                border-color: $satin-brass-hover;
            }

            &::placeholder {
                color: $white;
                opacity: 0.5;
            }
        }

        button {
            width: 125px;
        }
    }
}

.navLinks {
    composes: container from "../../styles/layout.module.scss";
    font-size: 12px;
}

.primaryMenu {
    display: flex;
    gap: $grid-gap;

    > * {
        width: percentage(math.div(2, 12));

        @media ($below-mobile) {
            width: percentage(math.div(1, 2));
        }
    }
}

.primaryDoubleWide {
    width: calc(#{percentage(math.div(4, 12))} + #{$grid-gap});

    dl > div {
        columns: 2;
        column-gap: $grid-gap;
    }

    @media ($below-mobile) {
        width: percentage(math.div(1, 1));
    }
}

.primaryHiddenOnMobile {
    @media ($below-mobile) {
        display: none;
    }
}

.featuredLink {
    margin-bottom: 8px;
}

.social {
    margin-top: 40px;

    @media ($below-tablet) {
        margin-top: 30px;
    }

    @media ($below-mobile) {
        margin-top: 10px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media ($below-smallest) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    a {
        display: inline-block;
        padding: 12px;
        color: inherit;
        line-height: 1;
        opacity: 1;
        transition: opacity 0.1s;

        &:hover {
            opacity: 0.7;
        }

        @media ($below-smallest) {
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }
}

.featuredList,
.secondaryMenuList,
.socialList {
    composes: listReset from "../../styles/utility.module.scss";
}

.secondaryMenu {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px 0 12px;
    }

    li {
        position: relative;
        line-height: 1em;
        &:not(:first-child) {
            &:before {
                content: "";
                width: 1px;
                height: 1em;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: rgba($white, 0.5);
            }
        }
    }

    a {
        padding: 5px 8px;
    }
}

.legal {
    composes: container from "../../styles/layout.module.scss";
    margin-top: 0;
    margin-bottom: 0;
    font-size: 11px;
    line-height: 1.27em;
    text-align: center;
}
