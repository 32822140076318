@import "../../styles/variables";

.button {
    & [data-reach-listbox-arrow] {
        display: flex;
        margin-left: 15px;
        width: 10px;
        height: 10px;

        & svg {
            transform: rotate(90deg);
        }
    }

    @include textinput;
    align-items: center;
    color: $black;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    overflow: hidden;
    white-space: nowrap;

    &[aria-expanded="true"] {
        border-color: $black;
        outline: none;

        & svg {
            transform: rotate(-90deg);
        }
    }

    &.placeholderSelected {
        color: var(--dropdown--placeholder-selected, $pewter3);
    }

    &.inputError {
        border-color: $red;
    }
}

.pseudoLabel {
    composes: primaryAllCaps from "../../styles/typography.module.scss";
    padding-bottom: 10px;
}

.placeholder {
    color: $pewter3;
}

.popover {
    background-color: $white;
    border: 1px solid $black;
    border-top: none;
    cursor: pointer;
    max-height: 400px;
    overflow: auto;
    z-index: 1;
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;

    &:focus {
        border: none;
        outline: none;
    }

    &[data-reach-menu-items] {
        padding: 0;
        white-space: normal;
    }

    & [data-reach-listbox-option],
    & [data-reach-menu-item] {
        padding: 12px 15px;
        font-family: var(--dropdown-list--font-family, inherit);
        font-size: 14px;

        &:hover,
        &[data-current-nav],
        &[data-selected] {
            background-color: $pewter1;
            color: $black;
        }

        &[aria-disabled="true"] {
            color: $pewter3;
        }
    }
}
